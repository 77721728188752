import Plan from '@/models/Plan'
import axios from 'axios'
import Channel from '@/models/Channel'
import TacticPlatform from '@/models/TacticPlatform'
import ID from '@/models/ID'

const PLAN_PATH = 'plan'
const PLANS_PATH = 'plans'
const VISITED_USER_PATH = 'plan-visited'
const UPDATE_VISITED_USER_PATH = 'user-visited-plan'
const UPDATE_PLAN_NAME_PATH = 'update-plan-name'

export default class PlansService {
  public create(newPlan: Plan, shouldCreateDefaultChannels = true) {
    return new Promise((resolve, reject) => {
      axios
        .post(PLANS_PATH, {
          ...newPlan.forRequestObject(),
          id: null,
          shouldCreateDefaultChannels: shouldCreateDefaultChannels,
        })
        .then(
          (response) => {
            resolve(Plan.fromResponseObject(response.data))
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  public get(ids: number[]) {
    return new Promise((resolve, reject) => {
      let sanitizedIds = ids
      if(ids.length){
        sanitizedIds = ids.filter((id) => id !== 0)
      }
      if(sanitizedIds.length == 0){
        resolve(new Plan())
        return
      }

      axios.get(`${PLANS_PATH}/${sanitizedIds.toString()}`).then(
        (response) => {
          sanitizedIds.length !== 0
            ? resolve(Plan.fromResponseObject(response.data))
            : resolve(
                response.data.map((plan) => Plan.fromResponseObject(plan))
              )
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  // TODO: remove super plan methods
  public getChannelsFromSuperPlan() {
    return new Promise((resolve, reject) => {
      axios.get(`${PLANS_PATH}/${Plan.SUPER_PLAN_ID.toString()}`).then(
        (response) => {
          resolve(
            response.data.channels.map((channel) =>
              Channel.fromResponseObject(channel)
            )
          )
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  // TODO: remove super plan methods
  public getPlatformsFromSuperPlan() {
    return new Promise((resolve, reject) => {
      axios.get(`${PLANS_PATH}/${Plan.SUPER_PLAN_ID.toString()}`).then(
        (response) => {
          resolve(
            response.data.tacticPlatforms.map((platform) =>
              TacticPlatform.fromResponseObject(platform)
            )
          )
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public update(updatedPlan: Plan, changedFields?: string[]) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${PLANS_PATH}/${updatedPlan.id.intID}`, {
          ...updatedPlan.forRequestObject(),
        })
        .then(
          (response) => {
            resolve(Plan.fromResponseObject(response.data))
          },
          (error) => {
            reject(error)
          }
        )
    })
  }
  
  public updatePlanName(planId: ID, newName: string) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${UPDATE_PLAN_NAME_PATH}/${planId.intID}`, {
          name: newName
        })
        .then(
          (response) => {
            resolve(Plan.fromResponseObject(response.data))
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  public delete(ids) {
    return new Promise((resolve, reject) => {
      axios.delete(`${PLANS_PATH}/${ids.toString()}`).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public export(id: number) {
    return new Promise((resolve, reject) => {
      axios.get(`${PLAN_PATH}/${id}/export`).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public freeze(id: number) {
    return new Promise((resolve, reject) => {
      axios.patch(`${PLAN_PATH}/${id}`).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public unfreeze(id: number) {
    return new Promise((resolve, reject) => {
      axios.patch(`${PLAN_PATH}/${id}`).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public getViewedRoles() {
    return new Promise((resolve, reject) => {
      axios.get(`${VISITED_USER_PATH}`).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public updateVisitedPlan(payload) {
    return new Promise((resolve, reject) => {
      axios.post(UPDATE_VISITED_USER_PATH, payload).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  public exportPlan(id: number, startDate: string = '', endDate: string = '') {
    return new Promise((resolve, reject) => {
      let requestPath = `${PLANS_PATH}/${id}/export`
      if (startDate !== '' && endDate !== '') {
        requestPath = `${PLANS_PATH}/${id}/export?start_date=${startDate}&end_date=${endDate}`
      }
      axios.get(requestPath).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
