<template>
  <div class="view-wrapper account-detail-view">
    <ViewHeader title="Account Settings" :isFullViewport="true">
      <template #actions>
          <Button
            label="Close"
            class="p-button-outlined"
            @click="handleCloseAccountDetailView"
          />
        </template>
    </ViewHeader>
    <ViewMain :isFullViewport="true">
      <div class="p-mb-6">
        <p class="account-label">Account</p>
        <h3>{{ currentAccount.contactCompanyName }}</h3>
      </div>

      <div class="p-mb-6">
        <div class="p-d-flex p-jc-between p-ai-start p-mb-4">
          <div class="p-d-flex p-ai-center">
            <h3 class="p-mr-6">Account Management</h3>
            <p class="data-table-note">To transfer account ownership contact <a href="mailto:hello@annumplanning.com" class="text-link email-link">hello@annumplanning.com</a>.</p>
          </div>
        </div>
        <DataTable :value="generalInfo">
          <Column field="firstName" header="First Name" />
          <Column field="lastName" header="Last Name" />
          <Column field="email" header="Email" />
          <Column header="Role">
            <template #body> Account Owner </template>
          </Column>
        </DataTable>
      </div>

      <div class="p-mb-6">
        <div class="p-d-flex p-jc-between p-ai-end p-mb-4">
          <div class="p-d-flex p-ai-center">
            <h3 class="p-mr-6">Plans</h3>
            <p class="data-table-note">Click the pencil icon to edit the Plan name. Note: Nested Plan names are turned into two letter acroynms in the calendar views based on the first letter of each word, or if theere is one word, the first two letters of a one word name.</p>
          </div>
          <!-- BEGIN: Feature flagged for beta -->
          <div
            v-if="
              $store.getters.isUserAnnumTeamMember
            "
            class="p-d-flex"
          >
            <Button
              label="Add Individual Plan"
              icon="pi pi-plus-circle"
              @click="() => handleCreatePlan('Individual', null)"
              class="p-button-outlined"
            ></Button>
            <Button
              label="Add Lead Plan"
              icon="pi pi-plus-circle"
              @click="() => handleCreatePlan('Master', null)"
              class="p-ml-3"
            ></Button>
          </div>
          <!-- END: Feature flagged for beta -->
        </div>
        <DataTable
          :value="currentPlans"
          class="p-mb-4"
          editMode="row"
          dataKey="id.intID"
          :editingRows.sync="planEditingRows"
          @row-edit-init="onPlanRowEditInit"
          @row-edit-save="onPlanRowEditSave"
        >
          <Column columnKey="name" header="Name">
            <template #body="slotProps">
              <div
                class="p-d-flex p-jc-between"
                :class="!!slotProps.data.parentId.intID ? 'p-ml-5' : ''"
              >
                {{ slotProps.data.name }}
              </div>
            </template>
            <template #editor>
              <InputText v-model="currentEditingPlan.name" />
            </template>
          </Column>
          <Column columnKey="id" header="ID" headerStyle="text-align: center;" bodyStyle="text-align: center;">
            <template #body="slotProps">
              {{ slotProps.data.id.intID }}
            </template>
            <template #editor="slotProps">
              {{ slotProps.data.id.intID }}
            </template>
          </Column>
          <!-- BEGIN: Feature flagged for beta -->
          <Column
            v-if="
              $store.getters.isUserAnnumTeamMember
            "
          >
            <template #body="slotProps">
              <div class="p-d-flex p-jc-end">
                <Button
                  v-if="!slotProps.data.parentId.intID"
                  label="Add Nested Plan"
                  icon="pi pi-plus-circle"
                  @click="() => handleCreatePlan('Sub', slotProps.data.id)"
                ></Button>
              </div>
            </template>
          </Column>
          <!-- END: Feature flagged for beta -->
          <Column
            :rowEditor="true"
            style="width: 10%; min-width: 8rem"
            bodyStyle="text-align: right;"
          ></Column>
        </DataTable>
      </div>

      <div class="p-mb-6">
        <div class="p-d-flex p-jc-between p-ai-end p-mb-4">
          <div class="p-d-flex p-ai-center">
            <h3 class="p-mr-6">Seats</h3>
            <p :class="{'data-table-note': true, 'seat-limit-reached-message': currentAccount.seats.isAnyLimitReached}">Free up seats by removing team members from the Account. Clicking Edt to the right of their names under Team below. Contact <a class="text-link email-link" href="mailto:hello@annumplanning.com">hello@annumplanning.com</a> to add more seats to your Account.</p>
          </div>
        </div>
        <DataTable
          :value="currentSeats"
          class="p-mb-4"
        >
          <Column field="title" header="Type"></Column>
          <Column field="used" header="Using"></Column>
          <Column field="total" header="Out Of"></Column>
          <Column field="remaining" header="Remaining"></Column>
          <Column>
            <template #body="slotProps">
              <template v-if="slotProps.data.total > 0 && slotProps.data.remaining <= 0">
                <p class="seat-limit-reached-message" style="width: 25rem;">You have reached your Account's seat limit.</p>
              </template>
              <template v-else>
                <p style="width: 25rem;"></p>
              </template>
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="p-mb-6">
        <div class="p-d-flex p-jc-between p-ai-center p-mb-4">
          <div class="p-d-flex p-ai-center">
            <h3 class="p-mr-6">Team</h3>
            <p class="data-table-note">Click the pencil icon to change a team member's personal information, remove their access to a Plan, or remove them from the Account and free up their seat. Refer to our <a href="https://annumplanning.com/resources/guides/adding-and-removing-team-members/" target="_blank">guide</a> for detailed instructions.</p>
          </div>
        </div>
        <DataTable
          :value="$store.getters.currentAccountUniqueUserRoles"
          class="p-mb-4"
        >
          <Column field="firstName" header="First Name">
            <template #body="slotProps">
              <div class="p-d-flex p-ai-center">
                {{ getAccountUserForId(slotProps.data.userId).firstName }}
              </div>
            </template>
          </Column>
          <Column field="lastName" header="Last Name">
            <template #body="slotProps">
              <div class="p-d-flex p-ai-center">
                {{ getAccountUserForId(slotProps.data.userId).lastName }}
              </div>
            </template>
          </Column>
          <Column field="emailAddress" header="Email">
            <template #body="slotProps">
              <div class="p-d-flex p-ai-center">
                {{ getAccountUserForId(slotProps.data.userId).emailAddress }}
              </div>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="p-d-flex p-jc-end">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-text"
                  title="Edit"
                  @click="onEditUserClick(slotProps.data.user.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <div>
        <div class="p-mb-6 p-mt-6">
          <h3>Delete Account</h3>
          <p>
            Contact
            <a href="mailto:hello@annumplanning.com" class="text-link email-link"
              >hello@annumplanning.com</a
            >.
          </p>
        </div>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Plan, {PlanType} from '@/models/Plan'
import Account from '@/models/Account'
import Role from '@/models/Role'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import ID from '@/models/ID'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import UserRole from '@/models/UserRole'
import User from '@/models/User'
import OverlayPanel from 'primevue/overlaypanel'

Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('OverlayPanel', OverlayPanel)

export default Vue.extend({
  name: 'AccountDetail',
  components: {
    ViewHeader,
    ViewMain,
  },
  data: () => {
    return {
      currentEditingPlan: {} as Plan,
      planEditingRows: [],
    }
  },
  computed: {
    generalInfo(): {} {
      return [
        {
          firstName: this.currentAccount.contactFirstName,
          lastName: this.currentAccount.contactLastName,
          email: this.currentAccount.contactEmail,
        },
      ]
    },
    currentAccount(): Account {
      return this.$store.getters.currentAccount
    },
    canUserEditAccountProperties(): boolean {
      return this.$store.getters.currentAccountPermissionLevel <=
        Role.LEVEL_ACCOUNT_OWNER
        ? true
        : false
    },
    canUserManagePlansAndUsers(): boolean {
      return this.$store.getters.currentAccountPermissionLevel <=
        Role.LEVEL_ACCOUNT_OWNER
        ? true
        : false
    },
    currentPlans(): Plan[] {
      return this.$store.getters.allPlans
    },
    superAdminId(): ID {
      // Patty's user ID
      return ID.fromResponseObject(3, 'users')
    },
    accountOwnerId(): ID {
      const ownerUserRole = this.$store.getters.currentAccount.userRoles.find((userRole)=> userRole.roleId.intID == 1)
      return ownerUserRole ? ownerUserRole.userId : new ID()
    },
    currentSeats(): {}[] {
      const currentSeatsObj = this.$store.getters.currentAccount.seats
      let seatsArray = [
        {
          title:  'Account Owner / Admin / Contributor',
          used: currentSeatsObj.admin.current,
          total: currentSeatsObj.admin.total,
          remaining: currentSeatsObj.adminRemaining,
        },
        {
          title:  'Viewer',
          used: currentSeatsObj.viewer.current,
          total: currentSeatsObj.viewer.total,
          remaining: currentSeatsObj.viewerRemaining,
        },
      ]
      if(currentSeatsObj.isUniversalAccount){
        seatsArray = [
          {
            title:  'Any Role',
            used: currentSeatsObj.universal.current,
            total: currentSeatsObj.universal.total,
            remaining: currentSeatsObj.universalRemaining,
          },
        ]
      }
      return seatsArray
    },
  },
  methods: {
    handleCloseAccountDetailView(){
      if(window.history.length > 2){
        this.$router.back()
      }else{
        this.$router.replace('/')
      }
    },
    handleCreatePlan(planType: PlanType | string, parentId: ID | null) {
      const newPlan = new Plan()
      newPlan.name = 'New Plan'
      newPlan.type = planType as PlanType
      newPlan.accountId = this.$store.getters.currentAccount.id
      if (parentId) {
        newPlan.parentId = parentId
      }
      newPlan.creator = this.$store.getters.currentUser

      this.$store.getters.services.plans
        .create(newPlan, newPlan.type == PlanType.Sub ? false : true)
        .then(
          (responsePlan) => {
            this.$store.dispatch('updateCurrentPlan', responsePlan)
            this.$store.getters.currentAccount.plans.push(responsePlan)

            // Add nested plan ID to nested plans array on local lead plan
            const parentPlan = this.$store.getters.currentAccount.plans.find((plan)=>plan.id.intID === responsePlan.parentId?.intID)
            if(parentPlan){
              parentPlan.plans.push(responsePlan.id)
            }

            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.success,
              summary: 'Plan created.',
              life: 3000,
            })

            const planAdminRole = this.$store.getters.allRoles.find(
              (role) => role.name === 'Admin'
            )

            // Create UserRole for super Admin
            if(this.$store.getters.currentAccount.id.intID !== 1){
              const newUserRoleSuperAdmin = new UserRole()
              newUserRoleSuperAdmin.roleId = planAdminRole
                ? planAdminRole.id
                : ID.fromResponseObject(4, 'roles')
              newUserRoleSuperAdmin.userId = this.superAdminId
              newUserRoleSuperAdmin.accountId = this.$store.getters.currentAccount.id
              newUserRoleSuperAdmin.planId = responsePlan.id
              newUserRoleSuperAdmin.canViewBudget = true
              newUserRoleSuperAdmin.channels = responsePlan.channels.map(
                (channel) => channel.id
              )
              newUserRoleSuperAdmin.canEditAllChannels = true

              this.$store.getters.services.users.createUserRole(newUserRoleSuperAdmin).then(
                (userRoleResponse) => {
                  this.$store.getters.currentPlan.userRoles.push(userRoleResponse)
                  this.$store.getters.currentAccount.userRoles.push(userRoleResponse)

                  Vue.prototype.$toast.add({
                    severity: AlertMessageSeverity.success,
                    summary: 'Super admin added to new plan.',
                    life: 3000,
                  })
                },
                (error) => {
                  Vue.prototype.$toast.add({
                    severity: AlertMessageSeverity.error,
                    summary: 'Error adding super admin to new plan.',
                  })
                }
              )
            }

            // Create UserRole for Account Owner
            const newUserRoleAccountOwner = new UserRole()
            newUserRoleAccountOwner.roleId = planAdminRole
              ? planAdminRole.id
              : ID.fromResponseObject(4, 'roles')
            newUserRoleAccountOwner.userId = this.accountOwnerId
            newUserRoleAccountOwner.accountId = this.$store.getters.currentAccount.id
            newUserRoleAccountOwner.planId = responsePlan.id
            newUserRoleAccountOwner.canViewBudget = true
            newUserRoleAccountOwner.channels = responsePlan.channels.map(
              (channel) => channel.id
            )
            newUserRoleAccountOwner.canEditAllChannels = true

            this.$store.getters.services.users.createUserRole(newUserRoleAccountOwner).then(
              (userRoleResponse) => {
                this.$store.getters.currentPlan.userRoles.push(userRoleResponse)
                this.$store.getters.currentAccount.userRoles.push(userRoleResponse)

                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.success,
                  summary: 'Account Owner added to new plan.',
                  life: 3000,
                })
              },
              (error) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.error,
                  summary: 'Error adding account owner to new plan.',
                })
              }
            )
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'Error creating plan.',
            })
          }
        )
    },
    getAccountUserForId(userId: ID): User {
      const thisUser = this.$store.getters.allAccountUsers.find(
        (user) => user.id?.intID == userId.intID
      )
      return thisUser ? thisUser : new User()
    },
    onEditUserClick(userId: ID) {
      this.$router.push({
        path: `/account/user/${userId.intID}`,
      })
    },
    onPlanRowEditInit(event) {
      this.currentEditingPlan.name = event.data.name
    },
    onPlanRowEditSave(event) {
      if (this.currentEditingPlan.name === event.data.name) {
        return
      }

      const thisPlan = event.data as Plan
      thisPlan.accountId = this.$store.getters.currentAccount.id

      this.$store.getters.services.plans.updatePlanName(thisPlan.id, this.currentEditingPlan.name).then(
        (plan) => {
          event.data.name = plan.name

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Plan updated.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error updating plan.',
          })
        }
      )
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.account-detail-view {
  .view-main {
    h3 {
      margin-bottom: 0;
      @include font-mulish-light;
      font-size: 2rem;
    }
    p {
      &.account-label {
        margin-bottom: 0;
        color: #333333;
      }
      &.data-table-note {
        max-width: 69rem;
        color: #999;
      }
      &.seat-limit-reached-message {
        color: red;
      }
    }
    a {
      &.email-link {
        text-decoration: underline;
      }
    }
  }
}
</style>
