<template>
  <div>
    <div class="user-view" v-if="userForUserRole">
      <div id="avatar">
        <img
          v-if="
            userForUserRole &&
            userForUserRole.profileImage.fileLocation !== 'null'
          "
          :src="userForUserRole.profileImage.fileLocation"
          alt=""
        />
        <div class="no-pic" v-else></div>
      </div>
      <div id="firstName" v-if="!userRole.isEditing">
        {{ userForUserRole.firstName }}
        <template v-if="$store.getters.currentUser.isSuperUser">
          <span class="id-value"> ({{ userForUserRole.id.intID }})</span>
        </template>
      </div>
      <div id="lastName" v-if="!userRole.isEditing">
        {{ userForUserRole.lastName }}
      </div>
      <div id="email" v-if="!userRole.isEditing">
        <div class="row-email">
          {{ userForUserRole.emailAddress }}
        </div>
      </div>
      <div id="role" v-if="!userRole.isEditing">
        <span v-if="userRole.roleId" class="row-role">
          <template
            v-if="
              accountOwnerId && accountOwnerId.intID === userForUserRole.id.intID
            "
          >
            Account Owner <span style="opacity: 0; display: block; height: 0;">(ID {{ userForUserRole.id.intID }})</span>
          </template>
          <template v-else>
            {{ getRoleForId(userRole.roleId).title }} <span style="opacity: 0;">(ID {{ userForUserRole.id.intID }})</span>
          </template>
        </span>
      </div>
      <div id="budget" v-if="!userRole.isEditing && useBudgets && canUserViewBudgets">
        <div class="row-budget">
          <span v-if="userRole.canViewBudget">View</span>
          <span v-else>Hide</span>
        </div>
      </div>
      <div id="editRights " v-if="!userRole.isEditing">
        <div class="channel-edit-right">
          <div v-if="userRole.canEditAllChannels === false && userRole.channels.length === 0">None</div>
          <div
            v-else-if="userRole.canEditAllChannels === true || userRole.channels.length >= currentPlanChannels.length"
          >
            All
          </div>
          <div v-else>
            <span
              v-for="(channelId, channelIndex) in userRole.channels"
              :key="channelIndex"
              >{{
                getChannelForId(channelId) &&
                getChannelForId(channelId).name &&
                `${getChannelForId(channelId).name}${
                  channelIndex === userRole.channels.length - 1 ? '' : ', '
                }`
              }}
            </span>
          </div>
        </div>
      </div>

      <div
        id="toggleDropdown"
        v-if="
          canUserEditPlanUsers &&
          accountOwnerId && accountOwnerId.intID !== userForUserRole.id.intID &&
          ($store.getters.currentUser.id.intID !== userForUserRole.id.intID ||
            $store.getters.currentPlanPermissionLevel === accountOwnerRoleLevel)
        "
        @click="() => (userRole.isEditing = !userRole.isEditing)"
      >
        <i
          :class="`pi pi-chevron-${userRole.isEditing ? 'down' : 'right'}`"
        ></i>
      </div>
    </div>
    <div class="edit-user p-ai-center" v-if="userRole.isEditing">
      <div class="edit-user__field" id="firstName">
        <input
          class="p-inputtext"
          type="text"
          v-model="userForUserRole.editingValues.firstName"
          disabled
          autocomplete="off"
        />
      </div>
      <div class="edit-user__field" id="lastName">
        <input
          class="p-inputtext"
          type="text"
          v-model="userForUserRole.editingValues.lastName"
          disabled
          autocomplete="off"
        />
      </div>
      <div class="edit-user__field" id="email">
        <div class="row-email">
          <input
            class="p-inputtext"
            type="text"
            v-model="userForUserRole.editingValues.emailAddress"
            disabled
            autocomplete="off"
          />
        </div>
      </div>
      <div class="edit-user__field" id="role">
        <div class="row-role">
          <Dropdown
            v-model="userRole.editingValues.role"
            :options="roleOptions"
            optionLabel="title"
            placeholder="Select"
            dataKey="key"
            @change="onRoleChange"
          />
        </div>
      </div>
      <div class="edit-user__field" id="budget" v-if="useBudgets && canUserViewBudgets">
        <div class="row-budget">
          <Dropdown
            v-model="userRole.editingValues.canViewBudget"
            :options="budgetOptions"
            optionLabel="name"
            optionValue="code"
            :disabled="
              userRole.editingValues.role.id && 
              userRole.editingValues.role.id.intID !== 5 &&
              userRole.editingValues.role.id.intID !== 6
            "
          />
        </div>
      </div>
      <div class="edit-user__field channel-right p-d-flex p-flex-row p-jc-between" id="editRights">
        <MultiSelect
          v-model="userRole.editingValues.channels"
          :options="currentPlanChannels"
          optionLabel="name"
          optionValue="id"
          placeholder="Select"
          dataKey="key"
          :disabled="userRole.editingValues.role.id && userRole.editingValues.role.id.intID !== 5"
          style="flex-grow: 1; max-width: 120px;"
        >
          <template #value="slotProps">
            <template
              v-if="slotProps.value.length === currentPlanChannels.length"
              >All</template
            >
            <template v-else-if="slotProps.value.length === 0">None</template>
          </template>
        </MultiSelect>
        <Button
          class="p-button-text p-ml-2"
          icon="pi pi-check"
          @click="() => handleEditUser(userRole)"
        />
        <Button
          v-if="canUserEditPlanUsers"
          class="p-button-text"
          icon="pi pi-trash delete-icon"
          @click="(e) => handleDeleteUser(e, userRole)"
        />
      </div>
      <div class="edit-user-buttons">
        <Button
          v-if="canUserEditPlanUsers && isUserStatusPending"
          label="Resend Invite"
          class="p-button-text"
          @click="(e) => handleResendInvite(e, userRole.user)"
        />
        <Button
          v-if="canUserEditPlanUsers"
          label="Reset Password"
          class="p-button-text"
          @click="(e) => handleResetPassword(e, userRole.user)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ID from '@/models/ID'
import Role from '@/models/Role'
import Channel from '@/models/Channel'
import Plan from '@/models/Plan'
import UserRole from '@/models/UserRole'
import User, { UserStatus } from '@/models/User'
import { AlertMessageSeverity } from '@/models/AlertMessage'
import AccountSeats from '@/models/AccountSeats'

export default Vue.extend({
  name: 'PlanSettingsUserDetail',
  props: {
    userRole: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      budgetOptions: [
        {
          name: 'Hide',
          code: false,
        },
        {
          name: 'View',
          code: true,
        },
      ],
    }
  },
  computed: {
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    isLeadPlan(): boolean {
      return this.currentPlan.plans.length ? true : false
    },
    currentPlanChannels(): Channel[] {
      return this.currentPlan.channels.filter(
        (channel) => channel.name !== Channel.CHANNEL_NAME_INITIATIVES && ((this.isLeadPlan && !channel.isNested) || (!this.isLeadPlan && (channel.isLead || channel.isNested)) || (!channel.isLead && !channel.isNested))
      )
    },
    roleOptions(): {}[] {
      return this.$store.getters.allRoles
        .filter((role) => {
          if(role.level >= 250){
            // Filter out used roles in per-seat accounts
            if(!this.currentAccountSeats.universal || this.currentAccountSeats.universal.total < 1){
              // Admin and contributor seats
              if(this.currentAccountSeats.adminRemaining < 1 && role.level <= Role.LEVEL_CONTRIBUTOR){
                return false
              }
              // Viewer seats
              if(this.currentAccountSeats.viewerRemaining < 1 && role.level === Role.LEVEL_VIEWER){
                return false
              }
              return true
            }
            return true
          }
          return false
        })
    },
    useBudgets(): boolean {
      return this.currentPlan.useBudgets
    },
    canUserViewBudgets(): boolean {
      return this.$store.getters.currentUserRole.canViewBudget
    },
    userForUserRole(): User {
      return this.$store.getters.allAccountUsers.find(
        (user) => user.id.intID === this.userRole.userId.intID
      )
    },
    roleForUserRole(): Role {
      return this.$store.getters.allRoles.find(
        (role) => role.id.intID == this.userRole.roleId.intID
      )
    },
    accountOwnerRoleLevel(): number {
      return Role.LEVEL_ACCOUNT_OWNER
    },
    canUserEditPlanUsers(): boolean {
      return (
        this.$store.getters.currentPlanPermissionLevel <=
          Role.LEVEL_PLAN_ADMIN ||
        this.$store.getters.currentAccountPermissionLevel ==
          Role.LEVEL_ACCOUNT_OWNER
      )
    },
    accountOwnerId(): ID {
      const ownerUserRole = this.$store.getters.currentAccount.userRoles.find((userRole)=> userRole.roleId.intID == 1)
      return ownerUserRole ? ownerUserRole.userId : new ID()
    },
    isUserStatusPending(): boolean {
      return this.userForUserRole.status === UserStatus.pending
    },
    currentAccountSeats(): AccountSeats {
      return this.$store.getters.currentAccount.seats
    },
  },
  created: function () {
    this.updateEditingValuesOnUserRole()
  },
  methods: {
    updateEditingValuesOnUserRole() {
      this.userRole.editingValues.role = this.roleForUserRole
      this.userRole.editingValues.user = this.userForUserRole
      this.userRole.editingValues.channels = this.userRole.channels
    },
    getRoleForId(roleId: ID) {
      return this.$store.getters.allRoles.find(
        (role) => role.id.intID == roleId.intID
      )
    },
    getChannelForId(channelId: ID): Channel | undefined {
      return this.currentPlanChannels.find(
        (channel) => channel.id.apiID === channelId.apiID
      )
    },
    handleEditUser(userRole: UserRole) {
      userRole.isEditing = false

      const editedUserRole = userRole.transformForEditing()
      editedUserRole.planId = this.currentPlan.id
      editedUserRole.accountId = this.$store.getters.currentAccount.id

      if(userRole.roleId.intID === 4 || (userRole.roleId.intID === 5 && userRole.channels.length >= this.currentPlanChannels.length)){
        editedUserRole.canEditAllChannels = true
      }else{
        editedUserRole.canEditAllChannels = false
      }

      this.$store.state.services.users
        .updateUserRole(editedUserRole)
        .then(() => {
          this.$store.dispatch('refreshCurrentPlan', {openedChannels: [], planToUse: this.currentPlan}).then(()=>{
            this.updateEditingValuesOnUserRole()
          })

          // Update account and plan to refresh account seats
          this.$store.dispatch('refreshCurrentAccount').then(
            ()=>{
              this.$store.dispatch('refreshCurrentPlan') 
            }
          )
        })
        .catch((error) => {
          console.warn(error)
          if(error.message.includes('status code 422') || error.message.includes('status code 409')){
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'This email address is connected to a user who already has a Seat in this Account. If the user is not listed as having access to this Plan, give them access by clicking on the Select From Account drop down.',
            })
          }else{
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: 'Error editing user.',
              detal: error,
            })
          }
        })
    },
    handleDeleteUser(e, userRole: UserRole) {
      e.stopPropagation()
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to remove the team member's access to this plan?`,
        acceptClass: 'btn-delete-accept',
        target: e.currentTarget,
        accept: () => {
          this.userRole.isEditing = false
          this.$store.state.services.users.deleteUserRole(userRole.id.intID).then(() => {
            this.currentPlan.userRoles = this.currentPlan.userRoles.filter(
              (planUserRole) => planUserRole.id.intID !== userRole.id.intID
            )

            // Update account and plan to refresh account seats
            this.$store.dispatch('refreshCurrentAccount').then(
              ()=>{
                this.$store.dispatch('refreshCurrentPlan') 
              }
            )
          })
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    handleResendInvite(e, user: User) {
      e.stopPropagation()
      if (!user.resendInvite) {
        user.resendInvite = true
        this.$store.getters.services.users.resendInvite(user.id.intID).then(
        ()=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Invite resent.',
          })
        }, 
        (error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: `Error resending invitation. ${error}`,
          })
        }
      )
      }
    },
    handleResetPassword(e, user: User) {
      e.stopPropagation()
      this.$store.getters.services.users.forgotPassword(this.userForUserRole.emailAddress).then(
        ()=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Password reset email sent.',
            life: 3000,
          })
        }, 
        (error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: `Error sending password reset email. ${error}`,
          })
        }
      )
    },
    onRoleChange(event) {
      // Default to allow budget view
      this.userRole.editingValues.canViewBudget = true

      switch (event?.value?.id?.intID) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          // Account-level (1-3), Admin (4), or Contributor (5) role selected
          this.userRole.editingValues.channels = this.currentPlanChannels.map(
            (channel) => channel.id
          )
          break
        case 6:
          // Viewer role selected
          this.userRole.editingValues.channels = []
          break
        default:
          // Do nothing
          break
      }
    },
  },
})
</script>