export default class AccountSeats {
  constructor(
    public universal: { current: number; total: number } = {
      current: 0,
      total: 0,
    },
    public admin: { current: number; total: number } = {
      current: 0,
      total: 0,
    },
    public viewer: { current: number; total: number } = {
      current: 0,
      total: 0,
    }
  ) {}

  public get isUniversalAccount(): boolean {
    // "Starter" plan has universal seats - "Advanced" and "Enterprise" plans have admin and viewer seats
    return this.universal.total > 0 && this.admin.total === 0 && this.viewer.total === 0
  }

  public get isAllLimitsReached(): boolean {
    return this.allUsed >= this.allTotal
  }

  public get isAnyLimitReached(): boolean {
    if(this.isUniversalAccount){
      return this.universal.current >= this.universal.total
    }
    return (this.admin.total > 0 && this.admin.current >= this.admin.total) || (this.viewer.total > 0 && this.viewer.current >= this.viewer.total)
  }

  public get allTotal(): number {
    return this.isUniversalAccount ? this.universal.total :  this.admin.total + this.viewer.total
  }
  
  public get allUsed(): number {
    return this.isUniversalAccount ? this.universal.current : this.admin.current + this.viewer.current
  }

  public get allRemaining(): number {
    return this.allTotal - this.allUsed
  }

  public get adminRemaining(): number {
    return this.admin.total - this.admin.current
  }

  public get universalRemaining(): number {
    return this.universal.total - this.universal.current
  }

  public get viewerRemaining(): number {
    return this.viewer.total - this.viewer.current
  }

  public static fromResponseObject(obj): AccountSeats {
    if (!obj) return new AccountSeats()

    return new AccountSeats(
      obj.universal ? obj.universal : { current: 0, total: 0 },
      obj.admin ? obj.admin : { current: 0, total: 0 },
      obj.viewer ? obj.viewer : { current: 0, total: 0 },
    )
  }
}
